body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #ffffff;
}

.sidebar {
    padding: 20px;
    background-color: #f9f9f9;
    border-right: 1px solid #ccc;
}

.content {
    padding: 20px;
}

.lead-info-title {
    background-color: #007bff; /* Синий фон */
    color: #ffffff; /* Белый текст */
    padding: 10px 30px !important;
    margin-bottom: 20px !important;
    border-radius: 4px 4px 0 0; /* Добавьте скругление углов для более плавного перехода */
}

.client-info, .operator-comments, .dialogue, .new-message, .status {
    margin-bottom: 20px;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    background-color: #ffffff;
}

.box {
    background-color: #ffffff;
}

.message-container {
    height: 320px;
    overflow-y: auto;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    background-color: #f9f9f9;
}

.dialogue {
    background-color: #ffffff; /* Установить белый фон вместо бежевого */
}

.note-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.note-container-input {
    width: calc(100% - 90px);
}

.delete-button {
    background-color: #ff0000;
    color: #ffffff;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
}

.delete-button:hover {
    background-color: #cc0000;
}

.add-comment-button {
    background-color: #007bff;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    margin-top: 10px;
}

.add-comment-button:hover {
    background-color: #0056b3;
}

.status {
    margin-top: 20px;
}

.dialog-actions .MuiButton-root {
    background-color: #007bff;
    color: #ffffff;
    margin-top: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    margin-right: 10px;

}
.dialog-actions .MuiButton-root:hover {
    background-color: #0056b3; /* Темно-синий при наведении */
}

.message-container::-webkit-scrollbar {
    width: 8px;
}

.message-container::-webkit-scrollbar-thumb {
    background-color: #ffffff;
    border-radius: 4px;
}

#send-button {
    background-color: #007bff;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    margin-right: 10px;
}

#send-button:hover {
    background-color: #0056b3;
}

#refresh-button {
    background-color: #28a745;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
}

#refresh-button:hover {
    background-color: #218838;
}
